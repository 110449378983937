export const CONFIG = {
  //Staging
  // MonolithicApi: "https://sandbox-api.ratemetrics.com/",
  // UiUrl: "https://sandbox.ratemetrics.com/",
  // HeaderClass: "stagging-header",
  // IsStaging: true,
  
  //Live

   MonolithicApi: "https://live-api.ratemetrics.com/",
   UiUrl : "https://hotel.ratemetrics.com/",
   HeaderClass: "live-header",
   IsStaging: false,
   MapboxToken: "pk.eyJ1IjoiYWktdGhnLW1hcGJveCIsImEiOiJjbHBoejhmb2cwZHZlMmxtaDB1ZHVkMDk1In0.r6q0mw26CgDaalsxbYZEIw"
};
 